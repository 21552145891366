import React, { useState, useEffect, useCallback } from 'react';
import { IoOpenOutline } from 'react-icons/io5';
import { Link } from 'react-router-dom';

import Placeholder from 'components/UI/placeholder/Placeholder';
import Spinner from 'components/UI/spinner/Spinner';

import utils from 'utils/';
import api from 'api/';

import styling from './Customers.module.scss';

const Customers = () => {
    // State
    const [{ organizations, filter, isLoading }, setState] = useState({
        organizations: [],
        filter: 'all',
        isLoading: true
    });


    /**
     * Fetches all organizations that match the desired filter criteria.
     * @type {(function(): Promise<void>)|*}
     */
    const fetchOrganizations = useCallback(async () => {
        try {
            setState(prevState => ({ ...prevState, isLoading: true }));

            const organizations = await api.getAllOrganizations(filter);

            await utils.sleep(250);

            setState(prevState => ({ ...prevState, organizations, isLoading: false }));

        } catch (error) {
            console.error(error.message);
        }
    }, [filter]);


    /**
     * Changes the active filter.
     * @param filter {string} filters all available organizations
     */
    const changePhaseHandler = (filter) => {
        setState(prevState => ({ ...prevState, filter }));
    };


    /**
     * Fetches all organizations whenever the function
     * fetchOrganizations changes.
     */
    useEffect(fetchOrganizations, [fetchOrganizations]);


    // Available headlines
    const headlines = {
        all: 'All Customers',
        inTrial: 'In Trial Phase',
        trialExpired: 'Expired Trial',
        openInvoice: 'Open Invoice',
        locked: 'Locked'
    };


    return (
        <>
            <ul className={styling.menu}>
                <li
                    className={(filter === 'all') ? styling.active : styling.item}
                    onClick={() => changePhaseHandler('all')}
                >
                    All
                </li>

                <li
                    className={(filter === 'inTrial') ? styling.active : styling.item}
                    onClick={() => changePhaseHandler('inTrial')}
                >
                    In Trial
                </li>

                <li
                    className={(filter === 'trialExpired') ? styling.active : styling.item}
                    onClick={() => changePhaseHandler('trialExpired')}
                >
                    Trial Expired
                </li>

                <li
                    className={(filter === 'openInvoice') ? styling.active : styling.item}
                    onClick={() => changePhaseHandler('openInvoice')}
                >
                    Open Invoice
                </li>
            </ul>

            <h1>{headlines[filter]}</h1>

            <ul className={styling.list} hidden={isLoading || !organizations.length}>
                {organizations.map(organization => (
                    <li key={organization._id}>
                        <Link to={'/customer/' + organization._id} className={styling.organization}>
                            <div>
                                <div className={styling.name}>{organization.name}</div>
                                <div className={styling.plan}>{organization.billing?.plan}</div>
                            </div>

                            <div>
                                <IoOpenOutline/>
                            </div>
                        </Link>
                    </li>
                ))}
            </ul>

            <Placeholder title="No Organizations found" size="smaller" hidden={isLoading || organizations.length}>
                No organizations found that match the desired filters.
            </Placeholder>

            <Spinner hidden={!isLoading}/>
        </>
    );
};

export default Customers;