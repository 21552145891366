import config from 'config';

const _send = async (path, opts) => {
    const accessPass = localStorage.getItem('access-pass');

    const options = {
        ...opts,
        headers: {
            'Content-Type': 'application/json',
            ...(accessPass && { 'Authorization': 'Bearer ' + accessPass }),
            ...(opts.headers || {})
        },
        credentials: 'include'
    };

    const res = await fetch(config.connectivity.apiBaseUrl + path, options);

    const parsedResponse = await res.json();

    if (!parsedResponse.ok) {
        throw new Error(parsedResponse.message || 'failed to send request');
    }

    return parsedResponse.data || {};
};

const signIn = async (payload) => {
    const path = 'auth/admin/sign-in?mode=cookie';

    const opts = {
        method: 'POST',
        body: JSON.stringify(payload)
    };

    return await _send(path, opts);
};

const me = async () => {
    const path = 'auth/me';

    const opts = {
        method: 'GET'
    };

    return await _send(path, opts);
};

const getAllOrganizations = async (filter) => {
    const path = 'admin/organizations?filter=' + filter;

    const opts = {
        method: 'GET'
    };

    return await _send(path, opts);
};

const getOrganizationById = async (id) => {
    const path = 'admin/organizations/' + id;

    const opts = {
        method: 'GET'
    };

    return await _send(path, opts);
};

const updateOrganization = async (id, payload) => {
    const path = 'admin/organizations/' + id;

    const opts = {
        method: 'PATCH',
        body: JSON.stringify(payload)
    };

    return await _send(path, opts);
};

const addManualSubscription = async (payload) => {
    const path = 'admin/subscriptions/manual';

    const opts = {
        method: 'POST',
        body: JSON.stringify(payload)
    };

    return await _send(path, opts);
};

const getInvoiceUrl = async (invoiceId) => {
    const path = 'admin/invoices/' + invoiceId + '/url';

    const opts = {
        method: 'GET'
    };

    return await _send(path, opts);
};

const updateInvoicePaymentStatus = async (invoiceId, isPaid) => {
    const path = 'admin/invoices/' + invoiceId + '/payment-status';

    const opts = {
        method: 'PATCH',
        body: JSON.stringify({ isPaid })
    };

    return await _send(path, opts);
};

const service = {
    signIn,
    me,
    getAllOrganizations,
    getOrganizationById,
    updateOrganization,
    addManualSubscription,
    getInvoiceUrl,
    updateInvoicePaymentStatus
};

export default service;