import React from 'react';
import { IoLogOutOutline } from 'react-icons/io5';
import { withRouter } from 'react-router-dom';

import logo from 'assets/logos/ido.svg';

import styling from './Nav.module.scss';

const Nav = ({ history }) => {

    /**
     * Signs out the current user and redirects them to the sign in.
     */
    const signOut = () => {
        localStorage.clear();
        history.push('/auth/sign-in');
    };


    return (
        <nav className={styling.nav}>
            <div className={styling.container}>
                <img src={logo} className={styling.logo} alt="logo"/>

                <button className={styling.signOut} onClick={signOut}>
                    <IoLogOutOutline/>
                </button>
            </div>
        </nav>
    );
};

export default withRouter(Nav);