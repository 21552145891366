import React, { useState, useEffect } from 'react';
import { useSnackbar } from 'react-simple-snackbar';
import { IoCloseCircle } from 'react-icons/io5';

import Dropdown from 'components/UI/dropdown/Dropdown';
import Spinner from 'components/UI/spinner/Spinner';
import Button from 'components/UI/button/Button';
import Lottie from 'components/UI/lottie/Lottie';
import Input from 'components/UI/input/Input';
import Modal from 'components/UI/modal/Modal';

import config from 'config/';
import utils from 'utils/';
import api from 'api/';

import styling from './AddManualSubscription.module.scss';

const AddManualSubscription = ({ open, organizationId, refetchOrganization, closeHandler }) => {
    // State
    const [{ plan, price, url, invoiceNumber, isLoading }, setState] = useState({
        plan: config.plans[0].value,
        price: '',
        url: '',
        invoiceNumber: '',
        isLoading: false
    });


    // Hooks
    const [openSnackbar] = useSnackbar();


    /**
     * Handles input field changes.
     * @param name {string} name of the input field that has changed
     * @param value {string} the value that was entered into the input field
     */
    const changeHandler = ({ target: { name, value } }) => {
        if (name === 'price' && !config.regex.price.test(value)) {
            return;
        }

        if (name === 'price' && value === '.') {
            value = '0';
        }

        setState(prevState => ({ ...prevState, [name]: value }));
    };


    /**
     * Handles dropdown field changes.
     * @param value {string} the value that was selected
     */
    const dropdownChangeHandler = ({ value }) => {
        setState(prevState => ({ ...prevState, plan: value }));
    };


    /**
     * Closes the modal and re-fetches the organization's data.
     * @returns {Promise<void>}
     */
    const completeHandler = () => {
        closeHandler();
        refetchOrganization();
    };


    /**
     * Adds a manual subscription for the organization
     * with the provided organization ID.
     * @returns {Promise<void>}
     */
    const addManualSubscription = async () => {
        try {
            setState(prevState => ({ ...prevState, isLoading: true }));

            const payload = {
                organizationId,
                plan,
                price: +price
            };

            const res = await api.addManualSubscription(payload);

            setState(prevState => ({
                ...prevState,
                url: res.url,
                invoiceNumber: res.invoiceNumber,
                isLoading: false
            }));

        } catch (error) {
            console.error(error.message);
            closeHandler();
            await utils.sleep(500);
            openSnackbar(utils.capitalize(error.message));
        }
    };


    /**
     * Resets the state when the modal opens.
     */
    useEffect(() => {
        if (open) {
            setState({
                plan: config.plans[0].value,
                price: '',
                signedUrl: '',
                invoiceNumber: '',
                isLoading: false
            });
        }
    }, [open]);


    // Lets the user define the subscription details
    const createSubscription = (
        <>
            <h1>Add Subscription</h1>

            <h6>Subscription Plan</h6>
            <Dropdown
                options={config.plans}
                value={config.plans.find(p => p.value === plan) || null}
                changeHandler={dropdownChangeHandler}
            />

            <div className={styling.input}>
                <Input
                    value={price}
                    name="price"
                    onChange={changeHandler}
                    label="Total Net Price (€)"
                    placeholder="e.g. 4500"
                />
            </div>

            <div className={styling.controls}>
                <Button type="clear" onClick={closeHandler}>Cancel</Button>
                <Button onClick={addManualSubscription} disabled={!price || price === '0'}>Create</Button>
            </div>
        </>
    );


    // Allows the user to open the created invoice
    const success = (
        <div className={styling.success}>
            <button className={styling.close} onClick={completeHandler}>
                <IoCloseCircle/>
            </button>

            <div className={styling.tick}>
                <Lottie type="tick" autoplay/>
            </div>

            <h1>Invoice Ready</h1>

            <p>Invoice <b>#{invoiceNumber}</b> created successfully.</p>

            <a href={url} target="_blank" rel="noreferrer noopener">Open Invoice</a>
        </div>
    );


    // Define content
    let content = createSubscription;

    if (isLoading) {
        content = <Spinner/>;
    }

    if (url) {
        content = success;
    }


    return (
        <Modal open={open}>
            {content}
        </Modal>
    );
};

export default AddManualSubscription;