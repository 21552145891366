import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useSnackbar } from 'react-simple-snackbar';
import { IoArrowBackCircle, IoDocument } from 'react-icons/io5';
import { Link } from 'react-router-dom';

import Placeholder from 'components/UI/placeholder/Placeholder';
import SaveBanner from 'components/UI/saveBanner/SaveBanner';
import Dropdown from 'components/UI/dropdown/Dropdown';
import Spinner from 'components/UI/spinner/Spinner';
import Input from 'components/UI/input/Input';

import AddManualSubscription from './addManualSubscription/AddManualSubscription';

import config from 'config/';
import utils from 'utils/';
import api from 'api/';

import styling from './Customer.module.scss';

const Customer = ({ match }) => {
    // State
    const [state, setState] = useState({
        name: '',
        plan: '',
        subscriptionActiveSince: '',
        paidThroughDate: '',
        companyName: '',
        address: '',
        city: '',
        zipCode: '',
        countryCode: '',
        country: '',
        vat: '',
        accountingEmailAddress: '',
        invoices: [],
        showAddManualSubscriptionModal: false,
        loadingInvoices: [],
        isLoading: true
    });


    const {
        name,
        plan,
        subscriptionActiveSince,
        paidThroughDate,
        companyName,
        address,
        city,
        zipCode,
        countryCode,
        country,
        vat,
        accountingEmailAddress,
        invoices,
        showAddManualSubscriptionModal,
        loadingInvoices,
        isLoading
    } = state;


    // Refs
    const originalData = useRef({});


    // Hooks
    const [openSnackbar] = useSnackbar();


    /**
     * Fetches the organization data for the provided organization ID.
     * @type {(function(): Promise<void>)|*}
     */
    const fetchOrganization = useCallback(async () => {
        try {
            const organization = await api.getOrganizationById(match.params.id);

            const data = {
                companyName: organization.billing?.companyName || '',
                address: organization.billing?.address || '',
                city: organization.billing?.city || '',
                zipCode: organization.billing?.zipCode || '',
                countryCode: organization.billing?.countryCode || '',
                country: organization.billing?.organization || '',
                vat: organization.billing?.vat || '',
                accountingEmailAddress: organization.billing?.accountingEmailAddress || ''
            };

            originalData.current = data;

            setState(prevState => ({
                ...prevState,
                ...data,
                name: organization.name || '',
                plan: organization.billing?.plan || '',
                subscriptionActiveSince: organization.billing?.subscriptionActiveSince || '',
                paidThroughDate: organization.billing?.paidThroughDate || '',
                invoices: organization.invoices || [],
                isLoading: false
            }));

        } catch (error) {
            console.error(error.message);
        }
    }, [match.params.id]);


    /**
     * Handles input field changes.
     * @param name {string} name of the input field that was changed
     * @param value {string} value that was entered into the input field
     */
    const changeHandler = ({ target: { name, value } }) => {
        setState(prevState => ({ ...prevState, [name]: value }));
    };


    /**
     * Handles dropdown field changes.
     * @param label {string} the label of the item that was selected
     * @param value {string} the value that was selected
     */
    const dropdownChangeHandler = ({ label, value }) => {
        setState(prevState => ({ ...prevState, countryCode: value, country: label }));
    };


    /**
     * Toggles the visibility of the new invoice modal.
     */
    const toggleAddManualSubscriptionModalVisibility = () => {
        // Determine if billing profile is valid
        const billingProfileComplete = (
            originalData.current.companyName &&
            originalData.current.address &&
            originalData.current.city &&
            originalData.current.zipCode &&
            originalData.current.countryCode &&
            originalData.current.vat &&
            originalData.current.countryCode
        );

        // Show error message if billing profile is incomplete
        if (!showAddManualSubscriptionModal && !billingProfileComplete) {
            openSnackbar('Please fill out the entire billing profile before creating an invoice.');
            return;
        }

        // Update state
        setState(prevState => ({
            ...prevState,
            showAddManualSubscriptionModal: !prevState.showAddManualSubscriptionModal
        }));
    };


    /**
     * Opens the invoice with the provided ID
     * in a new tab.
     * @param invoiceId {string} ID of the invoice that should be opened
     * @returns {Promise<void>}
     */
    const viewInvoice = async (invoiceId) => {
        try {
            setState(prevState => ({ ...prevState, loadingInvoices: [...prevState.loadingInvoices, invoiceId] }));

            const res = await api.getInvoiceUrl(invoiceId);

            const a = document.createElement('a');

            a.setAttribute('href', res.url);
            a.setAttribute('target', '_blank');
            a.click();

            setState(prevState => {
                const prevStateCopy = utils.copy(prevState);
                prevStateCopy.loadingInvoices = prevStateCopy.loadingInvoices.filter(id => id !== invoiceId);
                return prevStateCopy;
            });

        } catch (error) {
            console.error(error.message);
            openSnackbar(utils.capitalize(error.message));
        }
    };


    /**
     * Changes the payment status of the invoice with the provided ID.
     * @param invoiceId {string} ID of the invoice that should be marked as paid or unpaid
     * @param isPaid {boolean} determines whether the invoice is currently paid or unpaid
     * @returns {Promise<void>}
     */
    const togglePaymentStatus = async (invoiceId, isPaid) => {
        try {
            setState(prevState => ({ ...prevState, loadingInvoices: [...prevState.loadingInvoices, invoiceId] }));

            const res = await api.updateInvoicePaymentStatus(invoiceId, !isPaid);

            await utils.sleep(250);

            setState(prevState => {
                const prevStateCopy = utils.copy(prevState);
                const index = prevStateCopy.invoices.findIndex(invoice => invoice._id === invoiceId);

                prevStateCopy.invoices[index] = res;
                prevStateCopy.loadingInvoices = prevStateCopy.loadingInvoices.filter(id => id !== invoiceId);

                return prevStateCopy;
            });

        } catch (error) {
            console.error(error.message);
            openSnackbar(utils.capitalize(error.message));
        }
    };


    /**
     * Updates the organization data.
     * @returns {Promise<void>}
     */
    const saveHandler = async () => {
        try {
            setState(prevState => ({ ...prevState, isLoading: true }));

            const payload = {
                companyName,
                address,
                city,
                zipCode,
                countryCode,
                country,
                vat,
                accountingEmailAddress
            };

            await api.updateOrganization(match.params.id, payload);

            originalData.current = payload;

            await utils.sleep(250);

            setState(prevState => ({ ...prevState, isLoading: false }));

            openSnackbar('Successfully updated customer data');

        } catch (error) {
            console.error(error.message);
            openSnackbar(utils.capitalize(error.message));
        }
    };


    /**
     * Fetches the organization data whenever the fetchOrganization
     * function changes.
     */
    useEffect(fetchOrganization, [fetchOrganization]);


    // Determine if data has changed
    const currentData = {
        companyName,
        address,
        city,
        zipCode,
        countryCode,
        country,
        vat,
        accountingEmailAddress
    };

    const hasChanged = JSON.stringify(currentData) !== JSON.stringify(originalData.current);


    const content = (
        <>
            <Link to="/" className={styling.back}>
                <IoArrowBackCircle/>
                <span>Back to all Customers</span>
            </Link>

            <h1>{name}</h1>

            <div className={styling.plan}>{plan}</div>

            <div className={styling.caption}>
                From {utils.formatDate(new Date(subscriptionActiveSince))} to {utils.formatDate(new Date(paidThroughDate))}
            </div>

            <h2>Billing Profile</h2>

            <div className={styling.wrapper}>
                <Input
                    value={companyName}
                    name="companyName"
                    onChange={changeHandler}
                    label="Company Name"
                    placeholder="Company Name"
                    larger
                />

                <Input
                    value={address}
                    name="address"
                    onChange={changeHandler}
                    label="Address"
                    placeholder="Address"
                    larger
                />

                <Input
                    value={city}
                    name="city"
                    onChange={changeHandler}
                    label="City"
                    placeholder="City"
                    larger
                />

                <Input
                    value={zipCode}
                    name="zipCode"
                    onChange={changeHandler}
                    label="Zip Code"
                    placeholder="Zip Code"
                    larger
                />

                <div>
                    <h6>Country</h6>
                    <Dropdown
                        options={config.countryCodes}
                        value={config.countryCodes.find(c => c.value === countryCode) || null}
                        changeHandler={dropdownChangeHandler}
                    />
                </div>

                <Input
                    value={accountingEmailAddress}
                    name="accountingEmailAddress"
                    onChange={changeHandler}
                    label="Accounting Email Address"
                    placeholder="Accounting Email Address"
                    test={config.regex.email}
                    larger
                />

                <Input
                    value={vat}
                    name="vat"
                    onChange={changeHandler}
                    label="VAT Number"
                    placeholder="VAT Number"
                    larger
                />
            </div>

            <h2 className={styling.subtitle}>Invoices</h2>

            <Placeholder title="No Invoices" size="smaller" hidden={invoices.length}>
                There are no invoices for {name} available.
            </Placeholder>

            <ul className={styling.list} hidden={!invoices.length}>
                {invoices.map((invoice) => (
                    <li className={styling.invoice} key={invoice._id}>
                        <div className={styling.icon}>
                            <IoDocument/>
                        </div>

                        <div>
                            <div className={styling.invoiceNumber}>
                                #{invoice.invoiceNumber}
                                <div className={invoice.isPaid ? styling.paid : styling.unpaid}/>
                                <span>{invoice.isPaid ? 'paid' : 'unpaid'}</span>
                            </div>

                            <div className={styling.timestamp}>
                                Created: {utils.formatDate(new Date(invoice.createdAt))}
                            </div>

                            <div className={styling.actions}>
                                <button
                                    className={styling.action}
                                    onClick={() => viewInvoice(invoice._id)}
                                >
                                    View
                                </button>

                                <button
                                    className={styling.action}
                                    onClick={() => togglePaymentStatus(invoice._id, invoice.isPaid)}
                                >
                                    Mark as {invoice.isPaid ? 'unpaid' : 'paid'}
                                </button>
                            </div>
                        </div>

                        <div
                            className={styling.invoiceSpinner}
                            hidden={!loadingInvoices.includes(invoice._id)}
                        >
                            <Spinner size="small"/>
                        </div>
                    </li>
                ))}
            </ul>

            <button
                className={styling.button}
                onClick={toggleAddManualSubscriptionModalVisibility}
                disabled={hasChanged}
            >
                Add Subscription
            </button>

            <SaveBanner hasChanged={hasChanged} saveHandler={saveHandler}/>

            <AddManualSubscription
                open={showAddManualSubscriptionModal}
                organizationId={match.params.id}
                refetchOrganization={fetchOrganization}
                closeHandler={toggleAddManualSubscriptionModalVisibility}
            />
        </>
    );


    return isLoading ? <Spinner/> : content;
};

export default Customer;