import React, { useEffect, useRef } from 'react';

import Button from 'components/UI/button/Button';

import styling from './SaveBanner.module.scss';

const SaveBanner = ({ hasChanged, saveHandler, children }) => {
    const banner = useRef({});

    /**
     * Shows the banner with a delay as it is
     * invisible initially to avoid flashing.
     */
    useEffect(() => {
        setTimeout(() => {
            if (banner?.current?.style) {
                banner.current.style.opacity = '1';
            }
        }, 700);
    }, []);

    return (
        <div className={hasChanged ? styling.bannerOpen : styling.banner} style={{ opacity: '0' }} ref={banner}>
            <div>
                <p>{children || 'There are unsaved changes. Please click the save button to save your changes.'}</p>
                <Button type='revertedLight' onClick={saveHandler}>Save</Button>
            </div>
        </div>
    );
};

export default SaveBanner;