import React from 'react';
import Select from 'react-select';

import utils from 'utils';

const Dropdown = ({ options, changeHandler, defaultValue, value, placeholder, searchable, disabled }) => (
    <Select
        options={options}
        defaultValue={defaultValue}
        value={value}
        onChange={changeHandler}
        placeholder={placeholder || 'Select...'}
        theme={theme => ({
            ...theme,
            colors: {
                ...theme.colors,
                primary: utils.getCSSVariable('--color-1'),
                primary25: utils.getCSSVariable('--color-2-contrast'),
                neutral05: utils.getCSSVariable('--color-2-dimmed'),
                neutral10: utils.getCSSVariable('--color-2-contrast'),
                neutral20: utils.getCSSVariable('--color-2-contrast'),
                neutral30: utils.getCSSVariable('--color-2-contrast'),
                neutral50: utils.getCSSVariable('--color-2-dark'),
                neutral60: utils.getCSSVariable('--color-2-contrast'),
                neutral70: utils.getCSSVariable('--color-2-contrast'),
                neutral80: utils.getCSSVariable('--color-1'),
                neutral90: utils.getCSSVariable('--color-2-contrast')
            }
        })}
        isSearchable={!!searchable}
        isDisabled={disabled}
    />
);

export default Dropdown;