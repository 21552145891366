import React from 'react';
import { Switch, Route } from 'react-router-dom';

import Authenticated from './components/layout/authenticated/Authenticated';
import Customers from './components/customers/Customers';
import Customer from './components/customer/Customer';
import Auth from './components/auth/Auth';

const App = () => {
    return (
        <Switch>
            <Route path="/auth/:mode" component={Auth}/>

            <Authenticated>
                <Route path="/" component={Customers} exact/>
                <Route path="/customer/:id" component={Customer} exact/>
            </Authenticated>
        </Switch>
    );
};

export default App;