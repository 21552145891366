import React, { useState, useEffect } from 'react';
import { useSnackbar } from 'react-simple-snackbar';

import Spinner from 'components/UI/spinner/Spinner';
import Input from 'components/UI/input/Input';

import { useStore } from 'context/';
import utils from 'utils/';
import api from 'api/';

import logo from 'assets/logos/ido.svg';

import styling from './Auth.module.scss';

const Auth = ({ history, location }) => {
    // State
    const [{ password, token, expiredMessageShown, showTokenStep, isLoading }, setState] = useState({
        password: '',
        token: '',
        expiredMessageShown: false,
        showTokenStep: false,
        isLoading: false
    });


    // Store
    const { 1: dispatch } = useStore();


    // Hooks
    const [openSnackbar] = useSnackbar();


    /**
     * Handles input field changes.
     * @param name {string} name of the input field
     * @param value {string} value that was entered into the input field
     */
    const changeHandler = ({ target: { name, value } }) => {
        setState(prevState => ({ ...prevState, [name]: value }));
    };


    /**
     * Formats the response and stores the relevant data in the global
     * state. It also stores the access pass and expiration time
     * in the localStorage, executes the expiration handler,
     * updates the document title and redirects the user.
     * @param res {object} authentication response
     */
    const handleAuthenticationResponse = (res) => {
        const update = {
            user: {
                ...res.user,
                organization: {
                    ...res.organization
                }
            }
        };

        dispatch({ type: 'update', payload: update });

        localStorage.setItem('access-pass', res.accessPass);
        localStorage.setItem('expiration-time', res.expiresAt);

        utils.expirationHandler(history, res.expiresAt);

        document.title = 'iDo Admin Panel | ' + res.user.email;

        history.push('/');
    };


    /**
     * Signs in a user with the given email and password.
     * @param e {object} event object
     * @returns {Promise<void>}
     */
    const signIn = async (e) => {
        try {
            e.preventDefault();

            setState(prevState => ({ ...prevState, isLoading: true }));

            const res = await api.signIn({ email: 'ido-admin@mosaic.mc', password, token });

            await utils.sleep(250);

            if (res.accessPass) {
                handleAuthenticationResponse(res);
            } else {
                setState(prevState => ({ ...prevState, showTokenStep: true, isLoading: false }));
            }

        } catch (error) {
            console.error(error.message);
            openSnackbar(utils.capitalize(error.message));
            setState(prevState => ({ ...prevState, isLoading: false }));
        }
    };


    /**
     * Shows a message if the user was redirected to the sign
     * in because his session has been expired.
     */
    useEffect(() => {
        if (location.expired && !expiredMessageShown) {
            openSnackbar('Your session has been expired. Please sign in to continue.');
            setState(prevState => ({ ...prevState, expiredMessageShown: true }));
        }
    }, [expiredMessageShown, location.expired, openSnackbar]);


    // Get access pass and expiration time from localStorage
    const accessPass = localStorage.getItem('access-pass');
    const expirationTime = localStorage.getItem('expiration-time');


    // Redirect if user is already signed in or
    // clear localStorage if user is not signed
    // in or session has expired.
    if (accessPass && expirationTime && new Date(parseInt(expirationTime)) > new Date()) {
        history.push('/');
    } else {
        localStorage.clear();
    }


    return (
        <main className={styling.container}>
            <form className={styling.box} onSubmit={signIn}>

                <img src={logo} alt="logo"/>

                <h1>Sign In</h1>

                <Input
                    value={password}
                    onChange={changeHandler}
                    placeholder="Password"
                    name="password"
                    label="Password"
                    type="password"
                    autoComplete="new-password"
                    hidden={showTokenStep}
                    required
                    larger
                />

                <Input
                    value={token}
                    onChange={changeHandler}
                    placeholder="2FA Token"
                    name="token"
                    label="2FA Token"
                    hidden={!showTokenStep}
                    larger
                />

                <div className={styling.buttonWrapper}>
                    <button className={styling.button} type="submit" hidden={isLoading}>
                        {showTokenStep ? 'Confirm' : 'Sign In'}
                    </button>

                    <Spinner size="small" hidden={!isLoading}/>
                </div>
            </form>
        </main>
    );
};

export default Auth;